import Header from "./components/header/Header";
import Home from "./pages/home/Home";
import Background from "./components/background/Background";
import Footer from "./components/footer/Footer";

import "./App.css";
import "./pages/home/home.css";
import React from "react";
import Wallet from "./pages/wallet/Wallet";
import CreateWallet from "./pages/wallet/createWallet";

import { Route, Routes } from "react-router-dom";
import SeedPhrase from "./pages/wallet/seedPhrase";
import Login from "./pages/wallet/login";
import Contact from "./components/contact/contact";
import About from "./components/about/About";
import Team from "./components/team/Team";
import Careers from "./components/careers/Careers";
import DemoSection from "./components/testnet/testnet";


function App() {
  return (
    <div className="App">
      <Background>
        <header>
          <Header />
        </header>
        <div className="main-body">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/wallet/create" element={<CreateWallet />} />
            <Route path="/seedPhrase" element={<SeedPhrase />} />
            <Route path="/wallet/load" element={<Login />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/team" element={<Team />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/demo" element={<DemoSection />} />
          </Routes>
        </div>
        <Footer />
      </Background>
    </div>
  );
}

export default App;
